#header {
    height: 80px;
    transition: all 0.5s;
    z-index: 997;
    transition: all 0.5s;
    padding: 20px 0;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.3);
}

#header.header-scrolled,
#header.header-pages {
    height: 60px;
    padding: 10px 0;
}

#header .logo h1 {
    font-size: 36px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 3px;
    text-transform: uppercase;
}

#header .logo h1 a,
#header .logo h1 a:hover {
    color: #00366f;
    text-decoration: none;
}

#header .logo img {
    padding: 0;
    margin: 7px 0;
    max-height: 26px;
}

  
.main-nav {
    /* Drop Down */
    /* Deep Drop Down */
  }

.main-nav,
.main-nav * {
    margin: 0;
    padding: 0;
    list-style: none;
}

.main-nav > ul > li {
    position: relative;
    white-space: nowrap;
    float: left;
}

.main-nav a {
    display: block;
    position: relative;
    color: #004289;
    padding: 10px 15px;
    transition: 0.3s;
    font-size: 14px;
    font-weight: 500;
}

.main-nav a:hover,
.main-nav .active > a,
.main-nav li:hover > a {
    color: #007bff;
    text-decoration: none;
}

.main-nav .drop-down ul {
    display: block;
    position: absolute;
    left: 0;
    top: calc(100% + 30px);
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    padding: 10px 0;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: ease all 0.3s;
}

.main-nav .drop-down:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}

.main-nav .drop-down li {
    min-width: 180px;
    position: relative;
}

.main-nav .drop-down ul a {
    padding: 10px 20px;
    font-size: 13px;
    color: #004289;
}

.main-nav .drop-down ul a:hover,
.main-nav .drop-down ul .active > a,
.main-nav .drop-down ul li:hover > a {
    color: #007bff;     
}

.main-nav .drop-down > a:after {
    content: "\f107";
    padding-left: 10px;
}

.main-nav .drop-down .drop-down ul {
    top: 0;
    left: calc(100% - 30px);
}

.main-nav .drop-down .drop-down:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
}

.main-nav .drop-down .drop-down > a {
    padding-right: 35px;
}

.main-nav .drop-down .drop-down > a:after {
    content: "\f105";
    position: absolute;
    right: 15px;
}

  
.mobile-nav {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 9999;
    overflow-y: auto;
    left: -260px;
    width: 260px;
    padding-top: 18px;
    background: rgba(19, 39, 57, 0.8);
    transition: 0.4s;
}

.mobile-nav * {
    margin: 0;
    padding: 0;
    list-style: none;
}

.mobile-nav a {
    display: block;
    position: relative;
    color: #fff;
    padding: 10px 20px;
    font-weight: 500;
}

.mobile-nav a:hover,
.mobile-nav .active > a,
.mobile-nav li:hover > a {
    color: #74b5fc;
    text-decoration: none;
}

.mobile-nav .drop-down > a:after {
    content: "\f078";
    padding-left: 10px;
    position: absolute;
    right: 15px;
}

.mobile-nav .active.drop-down > a:after {
    content: "\f077";
}

.mobile-nav .drop-down > a {
    padding-right: 35px;
}

.mobile-nav .drop-down ul {
    display: none;
    overflow: hidden;
}

.mobile-nav .drop-down li {
    padding-left: 20px;
}

.mobile-nav-toggle {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9998;
    border: 0;
    background: none;
    font-size: 24px;
    transition: all 0.4s;
    outline: none !important;
    line-height: 1;
    cursor: pointer;
    text-align: right;
}

.mobile-nav-toggle svg {
    margin: 18px 18px 0 0;
    color: #004289;
}

.mobile-nav-overly {
    width: 100%;
    height: 100%;
    z-index: 9997;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(19, 39, 57, 0.8);
    overflow: hidden;
    display: none;
}

.mobile-nav-active {
    overflow: hidden;
}

.mobile-nav-active .mobile-nav {
    left: 0;
}

.mobile-nav-active .mobile-nav-toggle i {
    color: #fff;
}

a {
    color: #007bff;
    transition: 0.5s;
}

a:hover,
a:active,
a:focus {
    color: #0b6bd3;
    outline: none;
    text-decoration: none;
}

@media (max-width: 991px) {
    #header {
        height: 60px;
        padding: 10px 0;
    }
  
    #header .logo h1 {
        font-size: 28px;
        padding: 8px 0;
    }
  
}
.testimonial-item .testimonial-img {
    width        : 120px;
    border-radius: 50%;
    border       : 4px solid #fff;
    float        : left;
}

.testimonial-item {
    text-align: left !important;
}

.testimonial-item h3 {
    font-size  : 20px;
    font-weight: bold;
    margin     : 10px 0 5px 0;
    color      : #111;
    margin-left: 120px;
}

.testimonial-item h4 {
    font-size  : 14px;
    font-weight: 300;
    color      : #999;
    margin     : 0 0 15px 0;
    margin-left: 120px;
}

.testimonial-item p {
    font-style: italic;
    margin    : 0 0 15px 120px;
}

.testimonial .carousel {
    display       : flex;
    flex-direction: column-reverse;
}

.testimonial .carousel .control-dots {
    position: relative;
}

.testimonial .carousel .control-dots .dot {
    width     : 12px;
    height    : 12px;
    background: #ddd;
    box-shadow: none;
}

.testimonial .carousel .control-dots .dot {
    background: #007bff;
    box-shadow: none;
}

@media (max-width: 767px) {
    #testimonials .testimonial-item .testimonial-img {
        float : none;
        margin: auto;
    }

    #testimonials .testimonial-item h3,
    #testimonials .testimonial-item h4,
    #testimonials .testimonial-item p {
        margin-left: 0;
    }
}


@media (min-width: 992px) {
    #testimonials .testimonial-item p {
        width: 80%;
    }
}
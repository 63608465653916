.pointer {
  cursor: pointer;
}

#body {
  margin-top: 80px;
  height: calc(100% - 80px);
  color: #444;
  font-family: "Open Sans", sans-serif;
}

@media (max-width: 991px) {
  #body {
    margin-top: 60px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
  color: #444;
}

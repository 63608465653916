.section-bg {
    background: #ecf5ff;
}

.main-pages {
    margin-top: 60px;
}

/*--------------------------------------------------------------
  # Intro Section
  --------------------------------------------------------------*/

#intro {
    width          : 100%;
    position       : relative;
    background     : url("../../images/intro-bg.png") center bottom no-repeat;
    background-size: cover;
    padding        : 120px 0 120px 0;
}

#intro .intro-img {
    width: 50%;
    float: right;
}

#intro .intro-info {
    width : 90% !important;
    float : none;
    margin: 50px auto 50px auto;
}

#intro .intro-info h2 {
    color        : #fff;
    margin-bottom: 40px;
    font-size    : 48px;
    font-weight  : 700;
}

#intro .intro-info h2 span {
    color          : #74b5fc;
    text-decoration: underline;
}

#intro .intro-info .btn-get-started,
#intro .intro-info .btn-services {
    font-size     : 14px;
    font-weight   : 600;
    letter-spacing: 1px;
    display       : inline-block;
    padding       : 10px 32px;
    border-radius : 50px;
    transition    : 0.5s;
    margin        : 0 20px 20px 0;
    color         : #fff;
}

#intro .intro-info .btn-get-started {
    background: #007bff;
    border    : 2px solid #007bff;
    color     : #fff;
}

#intro .intro-info .btn-get-started:hover {
    background  : none;
    border-color: #fff;
    color       : #fff;
}

#intro .intro-info .btn-services {
    border: 2px solid #fff;
}

#intro .intro-info .btn-services:hover {
    background  : #007bff;
    border-color: #007bff;
    color       : #fff;
}

.section-header h3 {
    font-size    : 36px;
    color        : #283d50;
    text-align   : center;
    font-weight  : 500;
    position     : relative;
    margin-bottom: 30px;
}

.section-header p {
    text-align    : center;
    margin        : auto;
    font-size     : 15px;
    padding-bottom: 30px;
    color         : #556877;
    width         : 50%;
}

/* Section with background
  --------------------------------*/

.section-bg {
    background: #ecf5ff;
}

#enquire {
    padding: 10px 0;
}

#enquire .card {
    background   : #00458f;
    border-color : #00458f;
    border-radius: 10px;
    margin       : 0 15px;
    padding      : 15px 0;
    text-align   : center;
    color        : #fff;
    transition   : 0.3s ease-in-out;
    height       : 100%;
}

#enquire .card:hover {
    background  : #003b7a;
    border-color: #003b7a;
}

#enquire .card i {
    font-size  : 48px;
    padding-top: 15px;
    color      : #bfddfe;
}

#enquire .card h5 {
    font-size  : 22px;
    font-weight: 600;
}

#enquire .card p {
    font-size: 15px;
    color    : #d8eafe;
}

#enquire .card .readmore {
    color        : #fff;
    font-weight  : 600;
    display      : inline-block;
    transition   : 0.3s ease-in-out;
    border-bottom: #00458f solid 2px;
}

#enquire .card .readmore:hover {
    border-bottom: #fff solid 2px;
}

#enquire .counters {
    padding-top: 40px;
}

#enquire .counters span {
    font-weight: bold;
    font-size  : 48px;
    display    : block;
    color      : #fff;
}

#enquire .counters p {
    padding  : 0;
    margin   : 0 0 20px 0;
    font-size: 14px;
    color    : #cce5ff;
}

.img_max_height {
    max-height: 235px;
}

/*--------------------------------------------------------------
  # Responsive Media Queries
  --------------------------------------------------------------*/

@media (max-width: 991px) {

    #intro {
        padding: 140px 0 60px 0;
    }

    #intro .intro-img {
        width : 80%;
        float : none;
        margin: 0 auto 25px auto;
    }

    #intro .intro-info {
        width     : 80%;
        float     : none;
        margin    : auto;
        text-align: center;
    }

    #enquire .card {
        margin: 0;
    }
}

@media (max-width: 768px) {
    .back-to-top {
        bottom: 15px;
    }
}

@media (max-width: 767px) {
    #intro .intro-info {
        width: 100%;
    }

    #intro .intro-info h2 {
        font-size    : 34px;
        margin-bottom: 30px;
    }

    .section-header p {
        width: 100%;
    }
}

@media (max-width: 574px) {
    #intro {
        padding: 100px 0 20px 0;
    }
}

.m-30 {
    margin: 0px 0px 30px 0px;
}

.m-20 {
    margin: 0px 0px 20px 0px;
}


@-webkit-keyframes bounceInUp {

    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function        : cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
        opacity          : 0;
        -webkit-transform: translate3d(0, 3000px, 0);
        transform        : translate3d(0, 3000px, 0);
    }

    60% {
        opacity          : 1;
        -webkit-transform: translate3d(0, -20px, 0);
        transform        : translate3d(0, -20px, 0);
    }

    75% {
        -webkit-transform: translate3d(0, 10px, 0);
        transform        : translate3d(0, 10px, 0);
    }

    90% {
        -webkit-transform: translate3d(0, -5px, 0);
        transform        : translate3d(0, -5px, 0);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform        : translate3d(0, 0, 0);
    }
}

@keyframes bounceInUp {

    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function        : cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
        opacity          : 0;
        -webkit-transform: translate3d(0, 3000px, 0);
        transform        : translate3d(0, 3000px, 0);
    }

    60% {
        opacity          : 1;
        -webkit-transform: translate3d(0, -20px, 0);
        transform        : translate3d(0, -20px, 0);
    }

    75% {
        -webkit-transform: translate3d(0, 10px, 0);
        transform        : translate3d(0, 10px, 0);
    }

    90% {
        -webkit-transform: translate3d(0, -5px, 0);
        transform        : translate3d(0, -5px, 0);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform        : translate3d(0, 0, 0);
    }
}

.bounceInUp {
    -webkit-animation-name: bounceInUp;
    animation-name        : bounceInUp;
    animation-duration    : 1s;
}

@-webkit-keyframes fadeInUp {
    from {
        opacity          : 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform        : translate3d(0, 100%, 0);
    }

    to {
        opacity          : 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform        : translate3d(0, 0, 0);
    }
}

@keyframes fadeInUp {
    from {
        opacity          : 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform        : translate3d(0, 100%, 0);
    }

    to {
        opacity          : 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform        : translate3d(0, 0, 0);
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name        : fadeInUp;
    animation-duration    : 1s;
}

.select_box {
    display         : block;
    background-color: #ecf5ff !important;
}

.collapse-bg {
    background-color: transparent;
}

.panel-body {
    background-color: #015dc0;
    padding         : 10px;
    border-radius   : 5px;
}

.panel-bg {
    background: transparent;
    color     : #ecf5ff !important;
    margin    : 10px 0;
}

#footer .copyright {
    text-align : center;
    padding-top: 30px;
}

.about_pad {
    padding-top: 60px;
}

.enquire_pad {
    padding-top: 60px;
}